.App {
  text-align: center;
}

.App-logo {
  height: 8vmin;
  pointer-events: none;
  margin: 5px;
}

.Redux-logo {
  height: 8vmin;
  pointer-events: none;
  margin: 5px;
}

.logos {
  display: flex;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
  .Redux-logo {
    animation: Redux-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes Redux-logo-spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.main-screen {
  background-color: rgb(0, 0, 0);
  max-width: 278px;
  overflow: hidden;
  min-height: 21px;
  max-height: 21px;
  padding: 25px 0;
  font-size: large;
  color: #a8ff57;
  border-radius: 4px;
}

.number-btn {
  width: 50px;
  height: 50px;
  background-color: #555555;
  border: none;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
}

.number-btn:hover {
  background-color: #7a7a7a;
  cursor: pointer;
}

.number-panel {
  background-color: #f4f4f4;
  padding: 25px;
  border-radius: 4px;
}

.error
  {
    position: relative;
    animation: shake 0.2s ease-in-out 0s 2;
    box-shadow: 0 0 0.5em red;
  }

@keyframes shake {
  0% { margin-left: 0rem; }
  25% { margin-left: 0.5rem; }
  75% { margin-left: -0.5rem; }
  100% { margin-left: 0rem; }
}